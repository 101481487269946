@font-face {
    font-family: SHSHeavy;
    src: url('fonts/SourceHanSans-Heavy.otf'), url('fonts/SourceHanSans-Bold.otf');
}
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.yellowText {
    color: #FC9B15
}

.greenText {
    color: #472AFC
}

.gradientText {
    background: linear-gradient(to right, rgba(20, 136, 246, 1) 0%, 13.82022500038147%, rgba(101, 4, 243, 1) 27.64045000076294%, 63.82022500038147%, rgba(254, 183, 10, 1) 100%);;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline-block;
}

.MuiTypography-root {
    font-family: SHSHeavy, sans-serif !important;
}