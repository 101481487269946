
.videoWrapper {
    height: calc(100vh - 115px);
    position: relative;
    background: #000000;
}

.imgBg {
    width: 100%;
    height: 100%;
    object-fit: contain;
    /*opacity: 0.7;*/
    background: transparent !important;
}

.targetTime {
    font-size: 40.02pt;
    line-height: 1;
    font-weight: bold;
    letter-spacing: 3px;
    margin-bottom: 20px;
}

.targetText {
    text-align: center;
    font-size: 33.52pt;
    font-weight: bold;
    line-height: 1.4;
    letter-spacing: 1px;
    margin-bottom: 30px;
}

.btnSlideTop {
    font-size: 27pt;
    color: #fff;
    background-color: #000;
    font-weight: 600;
    line-height: 1.5;
    border-radius: 0;
    /*cursor: pointer;*/
    padding: 10px 0;
    letter-spacing: 1pt;
    max-width: 100%;
    margin: auto;
    margin-bottom: 50px;
}


.contentVideo {
    color: black;
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transform: translateY(-50%);
    /*max-width: 1200px;*/
    margin: auto;
}

.countdown {
    display: inline-block;
}

.countdown li {
    float: left;
    margin: 0 30px;
    text-align: center;
}

.countdown li h1 {
    font-size: 90px;
    width: 200px;
    display: block;
    font-weight: bolder;
    margin: 20px auto;

}

.countdown p {
    margin: 0 0 10px;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 6px;
}

@media screen and (max-width: 1920px) {
    .countdown li h1 {
        font-size: 70px;
        margin: 15px auto;
    }

    .targetTime {
        font-size: 35pt;
    }

    .targetText {
        font-size: 21pt;
        margin-bottom: 20px;
    }

    .btnSlideTop {
        max-width: 90%;
        font-size: 20pt;
        letter-spacing: 0pt;
    }
}

@media screen and (max-width: 1200px) {
    .countdown li h1 {
        font-size: 60px;
        width: 120px;
        margin: 20px auto;
    }

    .targetTime {
        font-size: 30pt;
    }

    .targetText {
        font-size: 19pt;
        margin-bottom: 20px;
    }

    .btnSlideTop {
        font-size: 16pt;
        letter-spacing: 0pt;
        max-width: 100%;
    }
}

@media screen and (max-width: 960px) {
    .videoWrapper {
        height: calc(100vh - 41px);
        position: relative;
        background: #000000;
    }
}

@media (max-width: 902px) {
    .countdown li h1 {
        font-size: 40px;
        width: 80px;
        margin: 0px auto;
    }

    .targetTime {
        font-size: 15pt;
        margin-bottom: 5px;
    }

    .targetText {
        font-size: 13pt;
        margin-bottom: 5px;
    }

    .btnSlideTop {
        font-size: 10pt;
        padding: 3px 0;
        margin-bottom: 0px;
        /*max-width: 80%;*/
    }
}

@media (max-width: 695px) {
    .targetTime {
        font-size: 18pt;
        margin-bottom: 5px;
    }

    .targetText {
        font-size: 13px;
        margin-bottom: 5px;
        line-height: 1.2;
    }

    .countdown li {
        margin: 0 5px;
    }

    .countdown p {
        font-size: 9px;
    }

    h3 {
        font-size: 26px !important;
    }

    .countdown li h1 {
        font-size: 20px;
        width: 70px;
        margin: 0px auto;
    }

    .btnSlideTop {
        font-size: 7pt;
        max-width: 98%;
        margin-bottom: 0px;
    }
}

@media (max-width: 480px) {
    .countdown p {
        font-size: 8px;
        letter-spacing: 2px;
        margin-bottom: 0px;
    }

    .targetText {
        font-size: 10pt;
        margin-bottom: 5px;
        line-height: 1.2;
    }

    .btnSlideTop {
        max-width: 99%;
        font-size: 7pt;
    }
}
